<template>
    <footer>
        <div class="container">
            2021 - 2022, Todos os direitos reservados © RIO é uma marca distribuída exclusivamente por EUROEX®
        </div>
    </footer>
</template>
<script>
export default {
    
}
</script>

<style scoped>
    footer{
        margin-top: 30px;
        padding: 20px 0;
        background: #113960;
        color: #FFCA24;
    }

    footer .container{
        text-align: center;
        font-size: 14px;
    }
</style>
