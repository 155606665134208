<template>
  <div id="app">
    <rio-header />

    <div id="hero">
      <rio-video />
      <div id="hero-panel">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div id="call-to-action">
                <h1>A MARCA DO<br> CAMINHONEIRO<br>DE VERDADE</h1>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <p>A <b>RIO</b> é a melhor marca para caminhoneiros que buscam <b>qualidade</b> e excelente <b>custo benefício</b>.</p>
              <p>Nosso acervo conta com uma vasta opção de <b>faróis</b>, <b>acabamentos</b>, <b>latarias</b> e <b>suportes</b>, para os principais modelos de 
                caminhões <b>Volvo</b>, <b>Scania</b>, <b>Daf</b>, <b>Mercedes Bens</b>, <b>Iveco</b>, <b>Man</b> entre outros.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="products-panel">
      <div class="container">
        <div class="row mx-auto" v-if="products.length > 0">
          <div class="col-12 col-md-6 col-lg-4 mb-4" v-for="(product, index) in products" :key="index">
            <produto :info="product" :key="componentKey" />
          </div>
        </div>
        <div class="row mx-auto" v-if="products.length === 0 && searchProduct">
          <div class="col-12">
            Não há produtos referentes à sua busca: "<b>{{ searchProduct }}</b>"
          </div>
        </div>
      </div>
    </div>
    <rioFooter />
  </div>
</template>

<script>
import axios from "axios";
import Produto from "./components/Produto.vue";
import RioVideo from "./components/Video.vue";
import RioHeader from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import eventBus from "./eventBus"

export default {
  name: "App",
  components: {
    produto: Produto,
    rioVideo: RioVideo,
    rioHeader: RioHeader,
    rioFooter: Footer,
  },
  data(){
    return {
      info: [],
      products: [],
      searchProduct: '',
      componentKey: 0,
    };
  },
  methods:{
    forceRerender() {
      this.componentKey += 1;
    },
    updateProducts(){
      const filteredProducts = []
      if(this.info) {
        if(this.searchProduct){
          for( var i = 0; i < this.info.length; i++){
            if( this.filter(this.info[i], this.searchProduct) )
              filteredProducts.push(this.info[i])
          }
          this.products = filteredProducts
        }else{
          this.products = this.info;
        }
      }else{
        this.products = [];
      }
      this.forceRerender()
    },

    sanitize(text){
      if (text==='') return '';
      return text.replace( /  +/g, ' ' ).replace(/[^0-9 A-Z]+/gi,"").toUpperCase().trim();
    },
    fixWords: function( str ){
        str = str.toLowerCase();
        str = str.replace('-', '')
                  .replace('iv ', 'iveco ')
                  .replace('aux ', 'auxiliar ')
                  .replace('ext ', 'exterior ')
                  .replace('int ', 'interior ')
                  .replace('inf ', 'inferior ')
                  .replace('neb ', 'neblina ')
                  .replace('mb ', 'mercedes benz ')
                  .replace('vl ', 'volvo ')
                  .replace('sc ', 'scania ')
                  .replace('vw ', 'volkswagen ')
        return str.toUpperCase();
    },

    filter: function(obj, search){
      if( obj.CODPROD.includes(search) ){
        return true
      }else{
        var words = search.split(/[ ,]+/);
        var containCount = 0
        for(const word of words){
          if(obj.DESCRPROD.includes(word) || obj.MARCA.includes(word)){
            //console.log(words, word, obj.DESCRPROD, obj.DESCRPROD.includes(word), obj.MARCA, obj.MARCA.includes(word), ( obj.DESCRPROD.includes(word) || obj.MARCA.includes(word) ))
            containCount ++
          }
        }
        if(containCount * 100 / words.length > 65)
          return true
      }
      return false
    }
  },

  mounted() {
    axios
      .get("https://revenda.euroex.com.br/api/get_rio_pecas.php")
      //.get('http://localhost:8080/data/pecas.json')
      .then( (response) => {
        this.info = response.data
        for( var i = 0; i < this.info.length; i++){
          this.info[i].DESCRPROD = this.fixWords(this.info[i].DESCRPROD)
          this.updateProducts()
        }
      });
    
    eventBus.$on('onSearch', data => {
      this.searchProduct = this.sanitize(data)
      this.updateProducts()
    });

  },
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,400;0,700;0,900;1,900&display=swap');
	
	html, body, #app {
		background: #FFCA24;
		font-family: 'Mulish', sans-serif;
		font-size: 18px;
		min-height: 100vh;
		color: #113960;
	}

  header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  #hero{
    position: relative;
    align-items: center;
    justify-content: flex-start;
    background: #FFCA24;
    z-index: 0;
  }

  #hero video{
    margin-top: 105px;
    height: 500px;
  }
  
  #hero-panel #call-to-action h1{
    font-size: 45px;
    line-height: 1em;
    padding: 80px 0;
    padding-left: 10px;
    font-weight: 900;
    vertical-align: top;
  }

  #hero-panel .row div{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #products-panel{
    position: relative;
    background: #FFCA24;
    padding-top: 20px;
    z-index: 2;
  }

</style>