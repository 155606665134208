<template>
    <video ref="videoRef" autoplay="autoplay" muted loop></video>
</template>

<script>
export default {
    props:{
        video:{
            type: String,
            default: '/site.mp4'
        } 
    },
    mounted: function() {
        this.$refs.videoRef.src = this.video;
        this.$refs.videoRef.play();
     }
}
</script>

<style scoped>
    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>
