<template>
    <header>
        <div class="container">
            <div id="header-panel">
                <div id="pn-logo">
                    <img id="logo" :src="logo" @click="scrollToElem('body', 0)">
                </div>
                <div id="nav">
                    <!--
                    <div id="contacts">
                        <div class="phone">(62) 3230-5900</div>
                        <div class="email">vendas@marcario.com.br</div>
                    </div>
                    -->
                    <div id="search-pn">   
                        <div id="search-box">
                            <input type="text" name="q" placeholder="O que você procura?" @keyup="onSearch(); scrollToElem('#products-panel')" v-model="searchProduct" />
                            <img src="search.svg">
                        </div>
                    </div>
                    <!--
                    <div id="menu">
                        <mainMenu />
                    </div>
                    -->
                </div>    
            </div>
        </div>
    </header>
</template>

<script>
//import Menu from "./Menu.vue";
import $ from "jquery";
import eventBus from "../eventBus"

export default {
    name: 'Header',
    components: {
        //mainMenu: Menu,
    },
    props:{
        logo:{
            type: String,
            default: 'logo-rio.svg'
        }
    },
    data(){
        return {
            searchProduct: ''
        }
    },
    methods:{
        scrollToElem( elem, marginTop = 100 ){
            $('html, body').stop(true, false).animate({
                scrollTop: $(elem).offset().top - marginTop
            }, 1000);
        },

        onSearch(){
            eventBus.$emit('onSearch', this.searchProduct);
        }
    }
}
</script>

<style scoped>
header{
    min-height: 100px;
    width: 100%;
    background: #FFF;
}

#header-panel{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5px;
}

#pn-logo{
    cursor: pointer;
    position: relative;
}

#logo{
    height: 100px;
    margin: 0;
}

#nav{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
    flex-grow: 1;
}

#nav::after{
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 6px;
    margin-left: -1px;
    background: #FFCA24;
}

#contacts{
    padding: 0 30px;
    flex-grow: 1;
}

#contacts .contact-header{
    font-weight: bold;
    font-size: 12px;
}

#contacts div{
    color: #113960;
    font-size: 1.2em;
}

#search-pn{
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    flex-grow: 3;
}

#search-box{
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 100%;
    margin-right: 20px;
    box-sizing: border-box;
}

#search-box input{
    padding: 10px;
    width: 100%;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid #CCC;
    outline: none;
}

#search-box input:focus,
#search-box input:not(:placeholder-shown){
    background: #FFCA24;
}

#search-box img{
    position: absolute;
    top: 15px;
    right: 15px;
    pointer-events: none;
    width: 20px;
    height: 20px;
}


#menu{
    flex-grow: 1;
}


</style>