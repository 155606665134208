<template>
    <div class="produto">
        <div class="foreground">
            <div class="cod">Código {{ cod }}</div>
            <div class="image"
                :style="'background-image:url(https://revenda.euroex.com.br/assets/images/produtos/'+cod+'.jpg)'">
            </div>
            <div class="brand">
                {{ brand }}
                <div class="right-side"></div>
            </div>
            <div class="description">{{ description }}</div>
        </div>
        <div class="background"></div>
        <div class="bottom-line"></div>
    </div>
</template>

<script>
export default {
    props:{
        info:{
            type: Object[Object],
            default: ''
        } 
    },
    data(){ 
        return{
            cod: 0,
            description: '',
            brand: '',
            grupo: '',
        }
    },
    created(){
        this.cod = this.info.CODPROD
        this.description = this.info.DESCRPROD
        this.brand = this.info.MARCA
        this.grupo = this.info.CODGRUPOPROD

    },
}
</script>

<style scoped>
    .produto{
        position: relative;
    }

    .produto .foreground{
        position: relative;
        background: #FFF;
        z-index: 1;
        overflow: hidden;
        transition: 0.3s;
    }

    .produto:hover .foreground{
        box-shadow: 0px 0px 15px rgba(90, 90, 30, 0.5);
        transition: 0.3s;
    }

    .produto:hover .image{
        position: relative;
        transform: scale(1.1);
        transition: 0.4s;
        z-index: 0;
    }

    .produto .foreground .image{
        margin: 10px 10%;
        padding-top: 65%;
        background: center center no-repeat;
        background-size: contain;
        box-sizing: border-box;
        transition: 0.4s;
    }

    .produto .foreground .cod{
        position: relative;
        font-size: 12px;
        font-weight: bold;
        padding: 20px;
        padding-bottom: 0;
        z-index: 1;
    }

    .produto .foreground .brand{
        position: relative;
        background: #FFCA24;
        width: 65%;
        font-size: 14px;
        padding: 3px 20px;
        font-weight: 900;
        z-index: 1;
    }

    .produto .foreground .brand .right-side{
        position: absolute;
        background: #FFCA24;
        width: 100px;
        height: 100%;
        padding: 3px 20px;
        font-weight: 900;
        top: 0;
        right: -80px;
        margin: 0 45px 0 0;
        transform: skew(-45deg);
    }

    .produto .foreground .description{
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2em;
        padding: 20px;
        padding-top: 10px;
        min-height: 80px;
    }

    .produto .background{
        position: absolute;
        background: #163C61;
        height: 112px;
        width: 100%;
        left: -10px;
        bottom: 0;
        z-index: 0;
    }

    .produto .bottom-line{
        position: absolute;
        height: 10px;
        width: 100%;
        background-image: url(../assets/background-product.svg);
        bottom: -10px;
        left: -10px;
        background-size: cover;
        background-position: bottom right;
        background-repeat: no-repeat;
    }
</style>
